import {
	API_ENDPOINTS,
	Button,
	Card,
	CardBody,
	PageLayout,
	route,
	Variants,
	useDisabledContext,
	StringHelpers,
	useLangContext,
	ButtonTypes,
	Badge,
	Modal,
	Icons,
	Icon,
	ModalSizes,
	Alert,
	replaceKeyWithValue,
} from 'carrier-fe';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';

type ModuleType = {
	id: string;
	name: string;
	description: string;
	type: string;
	external_url: string;
	training_quiz_id: string;
	status: string;
	file: {
		id: string;
		url: string;
		type: string;
		size_bytes: number;
		size_human: string;
		name: string;
		extension: string;
		created_at: string;
		updated_at: string;
	};
	created_at: string;
	updated_at: string;
	deleted_at: string;
	order: number;
};

type StageType = {
	id: string;
	name: string;
	description: string;
	status: string;
	created_at: string;
	updated_at: string;
	deleted_at: string;
	training_modules: ModuleType[];
};

type SessionType = {
	name: string;
	requires_in_person_event: string;
	training_stages: StageType[];
	result: {
		id: string;
		training_course_id: string;
		individual_training_course_id: string;
		individual_training_event_id: string;
		result: string;
		historic_training_course_data: {
			training_course: {
				name: string;
				category: string;
				description: string;
			};
		};
		started_at: string;
		completed_at: string;
		expires_at: string;
		created_at: string;
		updated_at: string;
		deleted_at: string;
	};
};

function TrainingCourseProgress() {
	const { disabled } = useDisabledContext();
	const { id } = useParams();
	const navigate = useNavigate();
	const [session, setSession] = useState<SessionType | null>(null);
	const [loading, setLoading] = useState<boolean>(true);
	const [module, setModule] = useState<ModuleType | null>(null);
	const [isModalOpen, setModalOpen] = useState<boolean>(false);
	const [videoUrl, setVideoUrl] = useState<string>('');
	const [collapsedStages, setCollapsedStages] = useState<
		Record<string, boolean>
	>({});
	const { crud, fields } = useLangContext();

	useEffect(() => {
		fetchSession();
	}, [id]);

	useEffect(() => {
		if (session) {
			const inProgressStage = session.training_stages.find(
				(stage) => stage.status === 'in progress'
			);
			setCollapsedStages({ [inProgressStage?.id || '']: true });
		}
	}, [session]);

	const fetchSession = async () => {
		try {
			const response = await axios.get(
				route(API_ENDPOINTS.TRAINING.COURSE.SESSIONS, {
					trainingCourse: String(id),
				})
			);
			setSession(response.data.data);
		} catch (error) {
			console.error(error);
		} finally {
			setLoading(false);
		}
	};

	const handleToggleCollapse = (stageId: string) => {
		setCollapsedStages((prev) => ({ ...prev, [stageId]: !prev[stageId] }));
	};

	const handleModuleAction = async (stage: StageType, module: ModuleType) => {
		setModule(module);

		if (module.type === 'quiz' && module.training_quiz_id) {
			window.open(
				`/training/course/${id}/quiz/${module.training_quiz_id}/info`,
				'_blank'
			);
			return;
		}

		try {
			const response = await axios.get(
				route(API_ENDPOINTS.TRAINING.COURSE.RESOURCE, {
					trainingCourse: String(id),
					trainingCourseStage: stage.id,
					trainingCourseModule: module.id,
				})
			);

			if (module.type === 'youtube') {
				setVideoUrl(response.data.url);
				setModalOpen(true);
			} else {
				// TODO - Trigger download automatically
				window.open(response.data.url);
			}
		} catch (error) {
			console.error(error);
		} finally {
			fetchSession();
		}
	};

	const getIconStyle = (status: string) => {
		switch (status) {
			case 'completed':
				return { backgroundColor: '#EDFCF3', color: '#17B26A' };
			case 'in progress':
				return { backgroundColor: '#E9EFFB', color: '#1E82E6' };
			default:
				return { backgroundColor: '#F2F4F7', color: '#D0D5DD' };
		}
	};

	const getIcon = (type: string) => {
		switch (type) {
			case 'quiz':
				return Icons.LIST;
			case 'pdf':
				return Icons.DESCRIPTION;
			default:
				return Icons.PLAY_CIRCLE;
		}
	};

	const getActionIcon = (type: string, external_url?: string) => {
		if (type === 'slideshow' && external_url) {
			return Icons.OPEN_IN_NEW;
		}
		if (type === 'slideshow' || type === 'pdf') {
			return Icons.DOWNLOAD;
		}
		return Icons.OPEN_IN_NEW;
	};

	const renderModule = (stage: StageType, module: ModuleType) => (
		<Card
			key={module.id}
			className="mb-2"
		>
			<CardBody className="d-flex align-items-center justify-content-between gap-3 p-2">
				<div className="d-flex align-items-center">
					<div
						className="d-inline-flex align-items-center justify-content-center p-2 rounded-2"
						style={getIconStyle(module.status)}
					>
						<Icon icon={getIcon(module.type)} />
					</div>
					<div className="ms-3">
						<h6 className="mb-1">
							{StringHelpers.limit(module.name, 100)}
						</h6>
						<p className="text-muted mb-0">
							{module.type === 'pdf'
								? StringHelpers.upper(module.type)
								: StringHelpers.title(module.type)}
						</p>
						{module.description && (
							<p className="text-muted mb-0">
								{StringHelpers.limit(module.description, 200)}
							</p>
						)}
					</div>
				</div>
				<button
					className="btn p-2 d-inline-flex align-items-center justify-content-center text-muted"
					type="button"
					disabled={!module.status}
					onClick={() => handleModuleAction(stage, module)}
				>
					<Icon
						icon={getActionIcon(module.type, module.external_url)}
					/>
				</button>
			</CardBody>
		</Card>
	);

	const renderStage = (stage: StageType, index: number) => {
		const isCollapsed = collapsedStages[stage.id] || false;

		return (
			<Card key={stage.id}>
				<CardBody>
					<div className="d-flex align-items-center justify-content-between">
						<div>
							<h6 className="text-uppercase fw-bold text-primary fs-6">
								Stage {index + 1}
							</h6>
							<div className="d-flex gap-4 mb-1">
								<h4 className="mb-0">{stage.name}</h4>
								{stage.status && (
									<Badge
										label={StringHelpers.title(
											stage.status
										)}
										variant={
											stage.status === 'completed'
												? Variants.Success
												: Variants.Primary
										}
									/>
								)}
							</div>
							{stage.description && (
								<p className="text-muted mb-0">
									{StringHelpers.limit(
										stage.description,
										200
									)}
								</p>
							)}
						</div>
						<button
							className="btn d-flex align-items-center justify-content-center p-2"
							onClick={() => handleToggleCollapse(stage.id)}
							style={{
								transition: 'transform 0.2s ease-in-out',
								transform: isCollapsed ? 'rotate(-180deg)' : '',
							}}
						>
							<Icon icon={Icons.CHEVRON_DOWN} />
						</button>
					</div>
					{isCollapsed && (
						<div className="mt-4">
							{stage.training_modules.map((module) =>
								renderModule(stage, module)
							)}
						</div>
					)}
				</CardBody>
			</Card>
		);
	};

	const title = loading
		? ''
		: StringHelpers.title(session?.name || 'Training Event View');

	return (
		<PageLayout
			title={title}
			loading={loading}
		>
			{!session ? (
				<p>
					{StringHelpers.title(
						fields.load_page ||
							'Could not load page, please refresh and try again.'
					)}
				</p>
			) : (
				<>
					<div className="d-flex justify-content-between">
						<Button
							label={StringHelpers.title(
								crud?.buttons?.back?.default || 'Back'
							)}
							onClick={() => navigate(`/training/course/${id}`)}
							variant={Variants.Dark}
							type={ButtonTypes.Outline}
							className="mb-4"
							disabled={disabled}
						/>
						{session.result && (
							<Button
								label={StringHelpers.title(
									replaceKeyWithValue(
										crud?.pages?.view?.title ||
											'View :model',
										'model',
										fields.result || 'Result'
									)
								)}
								onClick={() =>
									navigate(
										`/training/result/${session.result.id}`
									)
								}
								variant={Variants.Dark}
								className="mb-4"
							/>
						)}
					</div>
					{session.requires_in_person_event === '1' && (
						<Alert className="mb-4">
							{StringHelpers.title(
								fields.in_person_event_outcome ||
									'This course will be completed based on the outcomes of the in-person event.'
							)}
						</Alert>
					)}
					{session.training_stages.length > 0 ? (
						session.training_stages.map((stage, index) =>
							renderStage(stage, index)
						)
					) : (
						<Card>
							<CardBody>
								<p>
									{fields.no_stages_modules ||
										'There are currently no stages & modules for this course.'}
								</p>
							</CardBody>
						</Card>
					)}
				</>
			)}

			<Modal
				open={isModalOpen}
				onClose={() => setModalOpen(false)}
				title={StringHelpers.title(module?.name || '')}
				closeText={crud?.buttons?.cancel?.default || 'Cancel'}
				size={ModalSizes.Large}
			>
				<iframe
					width="100%"
					height="350"
					src={videoUrl}
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
					referrerPolicy="strict-origin-when-cross-origin"
					allowFullScreen
				></iframe>
			</Modal>
		</PageLayout>
	);
}

export default TrainingCourseProgress;
