import {
	API_ENDPOINTS,
	DataTable,
	formatDate,
	FormInput,
	PageLayout,
	route,
	useLangContext,
	replaceKeyWithValue,
	StringHelpers,
	Variants,
} from 'carrier-fe';
import { useNavigate } from 'react-router-dom';
import { useMemo } from 'react';
import { ResultType } from '../../types/result';

function TrainingResults() {
	const navigate = useNavigate();
	const { fields, crud, models } = useLangContext();

	const title = useMemo(
		() =>
			StringHelpers.title(
				replaceKeyWithValue(
					crud?.pages?.index.title || ':model List ',
					'model',
					crud?.models?.training_result || 'Training Result'
				)
			),
		[crud, models]
	);

	const handleOptionSelect = (action: any, data: ResultType) => {
		const { id = '' } = data;

		const actions: Record<string, () => void> = {
			view: () => navigate(`/training/result/${id}`),
		};

		if (typeof action === 'string' && actions[action]) {
			actions[action]();
		}
	};

	const colMapping = (data: ResultType) => {
		const cols: any = [
			{
				id: 'course_name',
				label: StringHelpers.title(
					fields?.course_name || 'Course Name'
				),
				value: StringHelpers.title(data.course_name || '-'),
				sortable: true,
			},
			{
				id: 'category_name',
				label: StringHelpers.title(fields?.category || 'Category'),
				value: StringHelpers.title(data.category_name || '-'),
				sortable: true,
			},
			{
				id: 'completed',
				label: StringHelpers.title(fields?.result || 'Result'),
				value: StringHelpers.title(data.result || '-'),
				sortable: true,
			},
			{
				id: 'started_at',
				label: StringHelpers.title(fields?.started_at || 'Started'),
				value: formatDate(data.started_at) || '-',
				sortable: true,
			},
			{
				id: 'completed_at',
				label: StringHelpers.title(fields?.completed_at || 'Completed'),
				value: formatDate(data.completed_at) || '-',
				sortable: true,
			},
			{
				id: 'actions',
				label: StringHelpers.title(fields?.actions || 'Actions'),
				value: data.permissions.view ? (
					<FormInput
						type="select"
						label={StringHelpers.title(fields?.actions || 'Action')}
						placeholder={StringHelpers.title(
							fields?.select || 'Select'
						)}
						name="actions"
						value=""
						onChange={(value) => {
							if (!!value && typeof value === 'string') {
								handleOptionSelect(value, data);
							}
						}}
						options={[
							{
								label: StringHelpers.title(
									crud?.view || 'View'
								),
								value: 'view',
							},
						]}
						noMargin
					/>
				) : null,
			},
		];

		return cols;
	};

	return (
		<PageLayout title={title || 'Training Result List'}>
			<DataTable<ResultType>
				routeEndpoint={route(API_ENDPOINTS.TRAINING.RESULT.INDEX)}
				colMapping={colMapping}
				incrementPaginationButtons
				canViewArchived={false}
				variant={Variants.Info}
			/>
		</PageLayout>
	);
}

export default TrainingResults;
