import {
  API_ENDPOINTS,
  Button,
  Card,
  CardBody,
  CardHeader,
  Dropdown,
  PageLayout,
  route,
  Modal,
  Variants,
  useDisabledContext,
  StringHelpers,
  customToast,
  useLangContext,
  replaceKeyWithValue,
  ButtonTypes,
} from 'carrier-fe';
import { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate, Link } from 'react-router-dom';
import './training_events_view.css';

interface DataProps {
  data: Record<string, string | JSX.Element | null | undefined>;
}

const date_formatter = new Intl.DateTimeFormat(undefined, {
  dateStyle: 'short',
  timeStyle: 'short',
});

export const Status = {
  ALL_CLOSED: 'ALL_CLOSED',
  JOIN_EVENT_MODAL_OPEN: 'JOIN_EVENT_MODAL_OPEN',
  LEAVE_EVENT_MODAL_OPEN: 'LEAVE_EVENT_MODAL_OPEN',
} as const;

function Data(props: DataProps) {
  const { data } = props;

  const renderData = Object.entries(data).map(([key, value]) => {
    return (
      <div>
        <b>{StringHelpers.title(key)}</b>
        <div>{!!value ? value : '-'}</div>
      </div>
    );
  });

  return <div className="cq__container">{renderData}</div>;
}

function TrainingView() {
  const { disabled, setDisabled } = useDisabledContext();
  const { id } = useParams();
  const navigate = useNavigate();

  const [status, setStatus] = useState<keyof typeof Status>(
    Status.ALL_CLOSED
  );
  const [trainingEvent, setTrainingEvent] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const { crud, fields } = useLangContext();

  const currency_formatter = useMemo(
    () =>
      new Intl.NumberFormat(undefined, {
        style: 'currency',
        currency: 'GBP',
      }),
    []
  );

  useEffect(() => {
    if (!id) return;

    axios
      .get(
        route(API_ENDPOINTS.TRAINING.EVENT.VIEW, { trainingEvent: id })
      )
      .then((res) => setTrainingEvent(res.data.data))
      .catch(console.error)
      .finally(() => setLoading(false));
  }, [id]);

  const price = !!trainingEvent?.price_pence
    ? currency_formatter.format(trainingEvent.price_pence / 100)
    : StringHelpers.title(fields?.free || 'Free');

  const has_joined = trainingEvent?.joined
    ? StringHelpers.title(fields?.yes || 'Yes')
    : StringHelpers.title(fields?.no || 'No');

  const event_data_join =  {
    [fields.name || 'Name']: StringHelpers.title(trainingEvent?.name || '-'),
    [fields.description || 'Description']: StringHelpers.title(
      trainingEvent?.description || ''
    ),
    [fields.course || 'Course']: (
      <Link to={`/training/course/${trainingEvent?.training_course?.id}`}>
        {StringHelpers.title(trainingEvent?.training_course.name || '-')}
      </Link>
    ),
    [fields.event_at || 'Date']: trainingEvent
      ? date_formatter.format(new Date(trainingEvent.event_at))
      : '-',
    [fields.price || 'Price']: price,
    [fields.availability || 'Availability']: StringHelpers.title(
      trainingEvent?.availability_percentage + '%' || '-'
    ),
    [fields.joined || 'Joined']: has_joined,
    [fields.created_at || 'Created at']: trainingEvent
      ? date_formatter.format(new Date(trainingEvent?.created_at))
      : '-',
    [fields.updated_at || 'Updated at']: trainingEvent
      ? date_formatter.format(new Date(trainingEvent?.updated_at))
      : '-',
  };
  const event_data_leave =  {
    [fields.name || 'Name']: StringHelpers.title(trainingEvent?.name || '-'),
    [fields.description || 'Description']: StringHelpers.title(
      trainingEvent?.description || ''
    ),
    [fields.course || 'Course']: (
      <Link to={`/training/course/${trainingEvent?.training_course?.id}`}>
        {StringHelpers.title(trainingEvent?.training_course.name || '-')}
      </Link>
    ),
    [fields.event_at || 'Date']: trainingEvent
      ? date_formatter.format(new Date(trainingEvent.event_at))
      : '-',
  };

  const venue_data = {
    [fields.name || 'Name']: StringHelpers.title(
      trainingEvent?.training_venue.name || '-'
    ),
    [fields.capacity || 'Capacity']: StringHelpers.title(
      String(trainingEvent?.training_venue.capacity) || '-'
    ),
    [fields.address_line_1 || 'Address Line 1']: StringHelpers.title(
      trainingEvent?.training_venue.address.address_line_1 || '-'
    ),
    [fields.address_line_2 || 'Address Line 2']: StringHelpers.title(
      trainingEvent?.training_venue.address.address_line_2 || '-'
    ),
    [fields.address_line_3 || 'Address Line 3']: StringHelpers.title(
      trainingEvent?.training_venue.address.address_line_3 || '-'
    ),
    [fields.town_city || 'Town / City']: StringHelpers.title(
      trainingEvent?.training_venue.address.town_city || '-'
    ),
    [fields.state_county || 'State / County']: StringHelpers.title(
      trainingEvent?.training_venue.address.state_county || '-'
    ),
    [fields.postcode_zipcode || 'Postcode / Zipcode']: StringHelpers.title(
      trainingEvent?.training_venue.address.postcode_zipcode || '-'
    ),
    [fields.country || 'Country']: StringHelpers.title(
      trainingEvent?.training_venue.address.country || '-'
    ),
  };

  const actions_dropdown = [
    trainingEvent?.joined
      ? {
        label: crud?.buttons?.leave?.default || 'Leave',
        onClick: () => setStatus(Status.LEAVE_EVENT_MODAL_OPEN),
      }
      : {
        label: crud?.buttons?.join?.default || 'Join',
        onClick: () => setStatus(Status.JOIN_EVENT_MODAL_OPEN),
      },
  ];

  const gotoMap = () =>
    window.open(trainingEvent?.training_venue.map_url, '_blank');

  const confirmJoin = () => {
    if (!id) return;
    setDisabled(true);

    const payload = {};

    axios
      .post(
        route(API_ENDPOINTS.TRAINING.EVENT.INVITE.STORE, {
          trainingEvent: id,
        }),
        payload
      )
      .then((res) => {
        customToast({
          variant: Variants.Success,
          title: res?.data?.message || 'Success',
        });

        setTrainingEvent((e: any) => {
          if (!e) return;

          e.joined = new Date().toISOString();
          return e;
        });
        setStatus(Status.ALL_CLOSED);
      })
      .catch((err) =>
        customToast({
          variant: Variants.Danger,
          title:
            err?.response?.data?.message ||
            'Something went wrong...',
        })
      )
      .finally(() => setDisabled(false));
  };

  const confirmLeave = () => {
    if (!id || !trainingEvent?.invite) return;
    setDisabled(true);

    axios
      .delete(
        route(API_ENDPOINTS.TRAINING.EVENT.INVITE.DELETE, {
          trainingEvent: id,
          trainingEventInviteId: trainingEvent?.invite?.id,
        })
      )
      .then((res) => {
        customToast({
          variant: Variants.Success,
          title: res?.data?.message || 'Success',
        });

        setTrainingEvent((e: any) => {
          if (!e) return;

          e.joined = undefined;
          return e;
        });

        setStatus(Status.ALL_CLOSED);
      })
      .catch((err) =>
        customToast({
          variant: Variants.Danger,
          title:
            err?.response?.data?.message ||
            'Something went wrong...',
        })
      )
      .finally(() => setDisabled(false));
  };

  const title = loading
    ? ''
    : StringHelpers.title(trainingEvent?.name || 'Training Event View');

  return (
    <>
      <PageLayout
        title={title}
        loading={loading}
      >
        {!trainingEvent ? (
          <p>
            {StringHelpers.title(
              fields.load_page ||
              'Could not load page, please refresh and try again.'
            )}
          </p>
        ) : (
          <>
            <Button
              label={crud?.buttons?.back?.default || 'Back'}
              type={ButtonTypes.Outline}
              variant={Variants.Dark}
              className={'mb-4'}
              onClick={() => navigate(-1)}
            />
            <div className="cq__container-parent">
              <Card>
                <CardHeader
                  leftSlot={
                    <h3>
                      {replaceKeyWithValue(
                        ':model ' +
                        crud?.sub_titles?.details ||
                        'Details',
                        'model',
                        crud?.models?.training_event ||
                        'Training Event'
                      )}
                    </h3>
                  }
                  rightSlot={
                    <Dropdown
                      label={
                        crud?.buttons?.actions
                          ?.default || 'Actions'
                      }
                      variant={Variants.Dark}
                      type={ButtonTypes.Solid}
                      items={actions_dropdown}
                    />
                  }
                />
                <CardBody>
                  <Data data={event_data_join} />
                </CardBody>
              </Card>
            </div>

            <div className="cq__container-parent">
              <Card>
                <CardHeader
                  leftSlot={
                    <h3>
                      {replaceKeyWithValue(
                        ':model ' +
                        crud?.sub_titles?.details ||
                        'Details',
                        'model',
                        crud?.models?.training_venue ||
                        'Training Venue'
                      )}
                    </h3>
                  }
                  rightSlot={
                    <Button
                      label={
                        crud?.buttons?.map?.default ||
                        'Open Map'
                      }
                      variant={Variants.Dark}
                      onClick={gotoMap}
                    />
                  }
                />
                <CardBody>
                  <Data data={venue_data} />
                </CardBody>
              </Card>
            </div>
          </>
        )}
      </PageLayout>

      <Modal
        open={status === Status.JOIN_EVENT_MODAL_OPEN}
        onClose={() => setStatus(Status.ALL_CLOSED)}
        title={replaceKeyWithValue(
          crud?.modals?.join?.title || 'Join :name',
          'name',
          crud?.models?.training_event || 'Training Event'
        )}
        confirmText={
          disabled
            ? crud?.buttons?.join?.submitting || 'Joining...'
            : crud?.buttons?.join?.default || 'Join'
        }
        onConfirm={confirmJoin}
        disabled={disabled}
        variant={Variants.Primary}
      >
        <div className="w-full cq__container-parent">
          <p className="mb-4">
            {replaceKeyWithValue(
              crud?.modals?.join?.description?.[0] ||
              'Are you sure you want to join the following :name',
              'name',
              crud?.models?.training_event || 'Training Event'
            )}
          </p>
          <Data data={event_data_join} />
        </div>
      </Modal>

      <Modal
        open={status === Status.LEAVE_EVENT_MODAL_OPEN}
        onClose={() => setStatus(Status.ALL_CLOSED)}
        title={replaceKeyWithValue(
          crud?.modals?.leave?.title || 'Leave :name',
          'name',
          crud?.models?.training_event || 'Training Event'
        )}
        confirmText={
          disabled
            ? crud?.buttons?.leave?.submitting || 'Leaving...'
            : crud?.buttons?.leave?.default || 'Leave'
        }
        onConfirm={confirmLeave}
        disabled={disabled}
        variant={Variants.Danger}
      >
        <div className="w-full cq__container-parent">
          <p className="mb-4">
            {replaceKeyWithValue(
              crud?.modals?.leave?.description[0] ||
              'Are you sure you want to leave the following :name',
              'name',
              crud?.models?.training_event || 'Training Event'
            )}
          </p>
          <Data data={event_data_leave} />
        </div>
      </Modal>
    </>
  );
}

export default TrainingView;
