import {
	API_ENDPOINTS,
	Button,
	Card,
	CardBody,
	PageLayout,
	route,
	Variants,
	useDisabledContext,
	StringHelpers,
	useLangContext,
	ButtonTypes,
	SubTitle,
	formatDate,
	Badge,
	checkTheme,
	Themes,
	replaceKeyWithValue,
} from 'carrier-fe';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { ResultType } from '../../../types/result';

function TrainingResultView() {
	const { disabled } = useDisabledContext();
	const { id } = useParams();
	const navigate = useNavigate();

	const [trainingResult, setTrainingResult] = useState<ResultType>();
	const [loading, setLoading] = useState<boolean>(true);
	const { crud, fields } = useLangContext();

	useEffect(() => {
		if (!id) return;

		axios
			.get(
				route(API_ENDPOINTS.TRAINING.RESULT.VIEW, {
					trainingResult: id,
				})
			)
			.then((res) => setTrainingResult(res.data.data))
			.catch(console.error)
			.finally(() => setLoading(false));
	}, [id]);

	const title = StringHelpers.title(
		replaceKeyWithValue(
			crud?.pages?.result?.title || 'Result: :name',
			'name',
			trainingResult?.training_course?.name || ''
		)
	);

	return (
		<PageLayout
			title={title}
			loading={loading}
		>
			{!trainingResult ? (
				<p>
					{StringHelpers.title(
						fields.load_page ||
							'Could not load page, please refresh and try again.'
					)}
				</p>
			) : (
				<>
					<div className="mb-4 d-flex justify-content-between align-items-center">
						<Button
							label={StringHelpers.title(
								crud?.buttons?.back?.default || 'Back'
							)}
							onClick={() => navigate('/training/result')}
							variant={Variants.Dark}
							type={ButtonTypes.Outline}
							disabled={disabled}
						/>
					</div>

					<Card className="flex-column-reverse flex-xl-row overflow-hidden rounded-4">
						<CardBody>
							<SubTitle
								title={StringHelpers.title(
									crud?.sub_titles?.details || 'Details'
								)}
								className="mb-3"
								style={{ color: '#464C5E' }}
							/>

							<div className="d-flex gap-2 mb-4">
								{trainingResult?.training_course
									?.training_category?.name && (
									<Badge
										label={StringHelpers.title(
											trainingResult.training_course
												.training_category.name || '-'
										)}
										variant={Variants.Primary}
									/>
								)}
							</div>

							<div className="row gy-3 row-cols-2">
								<div className="col">
									<div className="fw-bold mb-1">
										{StringHelpers.title(
											fields?.name || 'Name'
										)}
									</div>
									<div>
										<Link
											to={`/training/course/${trainingResult?.training_course?.id}`}
										>
											{StringHelpers.title(
												trainingResult?.training_course
													?.name || '-'
											)}
										</Link>
									</div>
								</div>
								<div className="col">
									<div className="fw-bold mb-1">
										{StringHelpers.title(
											fields?.result || 'Result'
										)}
									</div>
									<div>
										<Badge
											label={StringHelpers.title(
												trainingResult?.result || '-'
											)}
											variant={
												trainingResult?.result ===
												'Passed'
													? Variants.Success
													: trainingResult?.result ===
													  'Failed'
													? Variants.Danger
													: Variants.Light
											}
										/>
									</div>
								</div>
								<div className="col-12">
									<div className="fw-bold mb-1">
										{StringHelpers.title(
											fields?.description || 'Description'
										)}
									</div>
									<div>
										{trainingResult?.training_course
											?.description || '-'}
									</div>
								</div>
								<div className="col">
									<div className="fw-bold mb-1">
										{StringHelpers.title(
											fields?.started_at || 'Started'
										)}
									</div>
									<div>
										{trainingResult?.started_at
											? formatDate(
													trainingResult.started_at
											  )
											: '-'}
									</div>
								</div>
								<div className="col">
									<div className="fw-bold mb-1">
										{StringHelpers.title(
											fields?.completed_at || 'Completed'
										)}
									</div>
									<div>
										{trainingResult?.completed_at
											? formatDate(
													trainingResult.completed_at
											  )
											: '-'}
									</div>
								</div>
								<div className="col">
									<div className="fw-bold mb-1">
										{StringHelpers.title(
											fields?.expires_at || 'Expires'
										)}
									</div>
									<div>
										{trainingResult?.expires_at
											? formatDate(
													trainingResult.expires_at
											  )
											: '-'}
									</div>
								</div>
							</div>
						</CardBody>
						{trainingResult?.training_course?.image?.url && (
							<img
								src={trainingResult.training_course.image.url}
								alt={trainingResult.training_course.name}
								className="object-fit-cover mx-auto w-100"
								style={{
									maxWidth: '500px',
								}}
							/>
						)}
					</Card>

					{checkTheme(Themes.Toshiba) &&
						trainingResult.toshiba_warranty_levels && (
							<Card>
								<CardBody>
									<SubTitle
										title={StringHelpers.title(
											fields?.warranty_level ||
												'Warranty Level'
										)}
										className="mb-3 w-100"
										style={{ color: '#464C5E' }}
									/>
									{trainingResult.toshiba_warranty_levels.map(
										(level, index) => (
											<div
												className="d-flex"
												key={index}
											>
												<div className="flex-shrink-0">
													<img
														src={level.image}
														alt={level.name}
														className="object-fit-cover"
														style={{
															height: '80px',
															width: '80px',
														}}
													/>
												</div>
												<div className="flex-grow-1 ms-3">
													<div className="fw-bold mb-1">
														{level.name}
													</div>
													<div>
														<div className="fw-bold mb-1">
															{`${
																fields.fgas_refcom_standard_required ||
																'FGAS / Refcom standard required'
															}:`}
														</div>
														<div>
															{level.fgas_refcom_standard_required
																? fields.yes ||
																  'Yes'
																: fields.no ||
																  'No'}
														</div>
													</div>
													<div>
														<div className="fw-bold mb-1">
															{`${
																fields.refcom_elite_required ||
																'Refcom Elite required'
															}:`}
														</div>
														<div>
															{level.refcom_elite_required
																? fields.yes ||
																  'Yes'
																: fields.no ||
																  'No'}
														</div>
													</div>
													<div>
														<div className="fw-bold mb-1">
															{`${
																fields.national_accounts_enabled ||
																'National accounts enabled'
															}:`}
														</div>
														<div>
															{level.national_accounts_enabled
																? fields.yes ||
																  'Yes'
																: fields.no ||
																  'No'}
														</div>
													</div>
												</div>
											</div>
										)
									)}
								</CardBody>
							</Card>
						)}
				</>
			)}
		</PageLayout>
	);
}

export default TrainingResultView;
