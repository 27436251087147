import {
	API_ENDPOINTS,
	Button,
	Card,
	CardBody,
	PageLayout,
	route,
	Variants,
	useDisabledContext,
	StringHelpers,
	useLangContext,
	ButtonTypes,
	SubTitle,
	formatDate,
	Badge,
	checkTheme,
	Themes,
	useAuthorisationContext,
	Permissions,
} from 'carrier-fe';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { CourseType } from '../../../types/course';
import UpcomingEvents from './upcoming-events';
import Stages from './stages';
import Results from './results';

function TrainingCourseView() {
	const { disabled } = useDisabledContext();
	const { id } = useParams();
	const navigate = useNavigate();

	const [trainingCourse, setTrainingCourse] = useState<CourseType>();
	const [loading, setLoading] = useState<boolean>(true);
	const { crud, fields } = useLangContext();

	const { hasPermissionAccess } = useAuthorisationContext();

	useEffect(() => {
		if (!id) return;

		axios
			.get(
				route(API_ENDPOINTS.TRAINING.COURSE.VIEW, {
					trainingCourse: id,
				})
			)
			.then((res) => setTrainingCourse(res.data.data))
			.catch(console.error)
			.finally(() => setLoading(false));
	}, [id]);

	const title = loading
		? ''
		: trainingCourse?.name || 'Training Event View';

	const startSession = () => {
		if (!id) return;

		axios
			.post(
				route(API_ENDPOINTS.TRAINING.COURSE.START, {
					trainingCourse: id,
				})
			)
			.then(() => navigate(`/training/course/${id}/progress`))
			.catch(console.error)
			.finally(() => setLoading(false));
	};

	const renderStartButton = () => {
		if (
			!hasPermissionAccess(
				Permissions.INDIVIDUAL_TRAINING_COURSE_STORE
			) ||
			(trainingCourse?.session &&
				trainingCourse.session.status === 'in progress') ||
			(trainingCourse?.training_stages &&
				trainingCourse.training_stages.length === 0)
		)
			return;

		return (
			<Button
				label={StringHelpers.title(fields.start || 'Start')}
				onClick={startSession}
				variant={Variants.Success}
				type={ButtonTypes.Solid}
				disabled={disabled}
			/>
		);
	};

	const renderResumeButton = () => {
		if (
			!trainingCourse?.session ||
			!trainingCourse?.session?.permissions.update
		)
			return;

		return (
			<Button
				label={StringHelpers.title(fields.resume || 'Resume')}
				onClick={() => navigate(`/training/course/${id}/progress`)}
				variant={Variants.Success}
				type={ButtonTypes.Solid}
				disabled={disabled}
			/>
		);
	};

	const getVariant = (status: string) => {
		switch (status) {
			case 'completed':
				return Variants.Success;
			case 'expiring':
				return Variants.Danger;
			case 'expired':
				return Variants.Danger;
			case 'abandoned':
			case 'in progress':
			default:
				return Variants.Warning;
		}
	};

	return (
		<PageLayout
			title={title}
			loading={loading}
		>
			{!trainingCourse ? (
				<p>
					{StringHelpers.title(
						fields.load_page ||
							'Could not load page, please refresh and try again.'
					)}
				</p>
			) : (
				<>
					<div className="mb-4 d-flex justify-content-between align-items-center">
						<Button
							label={StringHelpers.title(
								crud?.buttons?.back?.default || 'Back'
							)}
							onClick={() => navigate('/training/course')}
							variant={Variants.Dark}
							type={ButtonTypes.Outline}
							disabled={disabled}
						/>
						{renderStartButton()}
						{renderResumeButton()}
					</div>

					<Card className="flex-column-reverse flex-xl-row overflow-hidden rounded-4">
						<CardBody>
							<SubTitle
								title={StringHelpers.title(
									crud?.sub_titles?.details || 'Details'
								)}
								className="mb-3"
								style={{ color: '#464C5E' }}
							/>

							<div className="d-flex gap-2 mb-4">
								{trainingCourse?.session?.status && (
									<Badge
										label={StringHelpers.title(
											trainingCourse.session.status
										)}
										variant={getVariant(
											trainingCourse.session.status
										)}
									/>
								)}
								{trainingCourse.training_category && (
									<Badge
										label={StringHelpers.title(
											trainingCourse.training_category
												.name || '-'
										)}
										variant={Variants.Primary}
									/>
								)}
								{trainingCourse.requires_in_person_event ===
									'1' && (
									<Badge
										label={StringHelpers.title(
											fields?.requires_in_person_event ||
												'requires in person event'
										)}
										variant={Variants.Dark}
									/>
								)}
							</div>
							<div className="row gy-3 row-cols-2">
								<div className="col">
									<div className="fw-bold mb-1">
										{StringHelpers.title(
											fields?.name || 'Name'
										)}
									</div>
									<div>
										{trainingCourse?.name || '-'}
									</div>
								</div>
								<div className="col-12">
									<div className="fw-bold mb-1">
										{StringHelpers.title(
											fields?.description || 'Description'
										)}
									</div>
									<div>
										{trainingCourse?.description || '-'}
									</div>
								</div>
								<div className="col-6">
									<div className="fw-bold mb-1">
										{StringHelpers.title(
											fields?.requires_in_person_event ||
												'Required In Person Event'
										)}
									</div>
									<div>
										{StringHelpers.title(
											trainingCourse?.requires_in_person_event ===
												'1'
												? fields?.yes || 'Yes'
												: fields?.no || 'No'
										)}
									</div>
								</div>
								<div className="col-6">
									<div className="fw-bold mb-1">
										{StringHelpers.title(
											fields?.course_expiry_years ||
												'Course Expiry (Years)'
										)}
									</div>
									<div>
										{trainingCourse?.course_expiry_years ||
											'-'}
									</div>
								</div>
								<div className="col">
									<div className="fw-bold mb-1">
										{StringHelpers.title(
											fields?.course_started ||
												'Course started'
										)}
									</div>
									<div>
										{trainingCourse?.session?.created_at
											? formatDate(
													trainingCourse.session
														.created_at
											  )
											: '-'}
									</div>
								</div>
								<div className="col">
									<div className="fw-bold mb-1">
										{StringHelpers.title(
											fields?.course_last_activity ||
												'Course last activity'
										)}
									</div>
									<div>
										{trainingCourse?.session?.updated_at
											? formatDate(
													trainingCourse.session
														.updated_at
											  )
											: '-'}
									</div>
								</div>
							</div>
						</CardBody>
						{trainingCourse?.image?.url && (
							<img
								src={trainingCourse.image.url}
								alt={trainingCourse.name}
								className="object-fit-cover mx-auto w-100"
								style={{
									maxWidth: '500px',
								}}
							/>
						)}
					</Card>

					{checkTheme(Themes.Toshiba) &&
						trainingCourse.toshiba_warranty_levels && (
							<Card>
								<CardBody>
									<SubTitle
										title={StringHelpers.title(
											fields?.warranty_level ||
												'Warranty Level'
										)}
										className="mb-3 w-100"
										style={{ color: '#464C5E' }}
									/>
									{trainingCourse.toshiba_warranty_levels.map(
										(level, index) => (
											<div
												className="d-flex"
												key={index}
											>
												<div className="flex-shrink-0">
													<img
														src={level.image}
														alt={level.name}
														className="object-fit-cover"
														style={{
															height: '80px',
															width: '80px',
														}}
													/>
												</div>
												<div className="flex-grow-1 ms-3">
													<strong>
														{level.name}
													</strong>
													<div>
														<strong>
															{`${
																fields.fgas_refcom_standard_required ||
																'FGAS / Refcom standard required'
															}:`}
														</strong>
														<div>
															{level.fgas_refcom_standard_required
																? fields.yes ||
																  'Yes'
																: fields.no ||
																  'No'}
														</div>
													</div>
													<div>
														<strong>
															{`${
																fields.refcom_elite_required ||
																'Refcom Elite required'
															}:`}
														</strong>
														<div>
															{level.refcom_elite_required
																? fields.yes ||
																  'Yes'
																: fields.no ||
																  'No'}
														</div>
													</div>
													<div>
														<strong>
															{`${
																fields.national_accounts_enabled ||
																'National accounts enabled'
															}:`}
														</strong>
														<div>
															{level.national_accounts_enabled
																? fields.yes ||
																  'Yes'
																: fields.no ||
																  'No'}
														</div>
													</div>
												</div>
											</div>
										)
									)}
								</CardBody>
							</Card>
						)}

					<Stages stages={trainingCourse.training_stages} />

					{trainingCourse.requires_in_person_event === '1' && (
						<UpcomingEvents />
					)}

					<Results />
				</>
			)}
		</PageLayout>
	);
}

export default TrainingCourseView;
