import {
	Card,
	CardBody,
	PageLayout,
	Icon,
	Icons,
	useLangContext,
	CardHeader,
	Button,
	StringHelpers,
	Variants,
	route,
	API_ENDPOINTS,
	Spinner,
	useDisabledContext,
	Sizes,
	Badge,
} from 'carrier-fe';
import { ReactNode, useEffect, useRef, useState, useMemo } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

const DEBOUNCE_TIME = 1000;

const PAGE_SIZE = 50;
const SORT_BY = '-created_at';

const formatter = Intl.DateTimeFormat(undefined, {
	dateStyle: 'short',
	timeStyle: 'short',
});

interface GridProps<T> {
	endpoint: string;
	renderItem: (item: T) => ReactNode;
	searchable?: boolean;
	pagination?: boolean;
}

interface Item {
	id: string;
	distributor_id?: string;
	name: string;
	description?: string;
	training_course_id: string;
	event_at: string;
	training_venue_id: string;
	attendee_count: number;
	joined?: boolean;
	availability_percentage: number;
	requires_payment: boolean;
	price_pence?: number;
	currency?: string;
	created_at: string;
	updated_at: string;
	deleted_at?: string;
	permissions: {
		view: boolean;
		update: boolean;
		delete: boolean;
		restore: boolean;
	};
	distributor: string;
	venue: string;
	course: string;
}

interface ApiPaginatedListResponse<R> {
	data: {
		rows: R[];
		pagination: {
			pages: {
				current: number;
				total: number;
			};
			count: {
				current: number;
				total: number;
			};
		};
	};
}

function Grid<T extends Item>(props: GridProps<T>) {
	const { endpoint, renderItem, searchable, pagination } = props;
	const [searchParams, setSearchParams] = useSearchParams();
	const { crud } = useLangContext();

	const [items, setItems] = useState<T[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [search, setSearch] = useState<string>('');
	const [currentPage, setCurrentPage] = useState<number>(1);

	const { disabled } = useDisabledContext();

	const totalPages = useRef<number>(1);

	const fetch = () => {
		if (!loading) setLoading(true);

		axios
			.get<ApiPaginatedListResponse<T>>(
				endpoint + '?' + searchParams.toString()
			)
			.then((res) => {
				const { rows, pagination } = res.data.data;
				const { total, current } = pagination.pages;

				totalPages.current = total;
				setCurrentPage(current);
				setItems(rows);
			})
			.catch(console.error)
			.finally(reset);
	};

	const reset = () => {
		setLoading(false);
	};

	function sortItems<T extends Item>(items: T[]): T[] {
		return items.sort((a, b) => {
			const dateComparison = new Date(a.event_at).getTime() - new Date(b.event_at).getTime();

			if (dateComparison !== 0) {
				return dateComparison;
			}

			return a.name.localeCompare(b.name);
		});
	}

	const renderGrid = () => {
		sortItems(items);

		return items.map((item) => {
			return renderItem(item);
		});
	};

	const previousPage = () => {
		if (currentPage === 1) return;
		setCurrentPage((e) => e - 1);
	};

	const nextPage = () => {
		if (currentPage === totalPages.current) return;
		setCurrentPage((e) => e + 1);
	};

	useEffect(() => {
		const params: Record<string, string> = {};

		params['format'] = 'flat';
		params['page[size]'] = String(PAGE_SIZE);
		params['sort'] = SORT_BY;

		if (search && search.length > 0) params['filter[search]'] = search;
		if (currentPage) params['page[number]'] = String(currentPage);

		setSearchParams(params);
	}, [search, currentPage]);

	useEffect(() => {
		if (!endpoint) return;

		const debounce = setTimeout(() => fetch(), DEBOUNCE_TIME);
		return () => clearTimeout(debounce);
	}, [endpoint, searchParams.toString()]);

	return (
		<Card>
			<CardHeader
				leftSlot={
					searchable && (
						<div className={'d-flex flex-column align-items-start'}>
							<label
								htmlFor="search"
								className="visually-hidden"
							>
								{crud?.pages.index.search || 'Search'}
							</label>
							<div className="position-relative">
								<div className="position-absolute d-flex h-100 align-items-center ps-2 pe-none">
									<Icon
										icon={Icons.SEARCH}
										style={{ color: '#0d6efd' }}
									/>
								</div>
								<input
									type="search"
									id="search"
									name="search"
									value={search || ''}
									onChange={(e) =>
										setSearch(e.target.value || '')
									}
									className="form-control ps-5 py-2"
									placeholder={
										crud?.pages.index.search || 'Search'
									}
									disabled={disabled}
								/>
							</div>
						</div>
					)
				}
			/>
			<CardBody>
				<div className="row row-cols-1 row-cols-sm-2 row-cols-xxl-3 gy-4">
					{loading ? <Spinner /> : renderGrid()}
				</div>
				{pagination && (
					<div className="d-flex flex-row-reverse gap-3 mt-4">
						<Button
							label={crud?.buttons?.next?.default || 'Next Page'}
							onClick={nextPage}
							variant={Variants.Light}
							size={Sizes.Small}
							disabled={currentPage === totalPages.current}
						/>
						<Button
							label={
								crud?.buttons?.previous?.default ||
								'Previous Page'
							}
							onClick={previousPage}
							variant={Variants.Light}
							size={Sizes.Small}
							disabled={currentPage === 1}
						/>
					</div>
				)}
			</CardBody>
		</Card>
	);
}

function TrainingEvents() {
	const navigate = useNavigate();
	const { crud, fields } = useLangContext();
	const currency_formatter = useMemo(
		() =>
			new Intl.NumberFormat(undefined, {
				style: 'currency',
				currency: 'GBP',
			}),
		[]
	);

	const renderItem = (item: Item) => {
		const price = !!item.price_pence
			? currency_formatter.format(item.price_pence / 100)
			: StringHelpers.title(fields?.free) || 'Free';

		const date = new Date(item.event_at);
		const formatted_date = formatter.format(date);

		const gotoEvent = () => {
			if (!item.permissions.view) return;

			navigate(item.id);
		};

		return (
			<div
				className="col"
				onClick={gotoEvent}
				style={{ cursor: 'pointer' }}
			>
				<Card
					className={'h-100'}
					noMargin
				>
					<CardBody className="d-flex flex-column">
						<h4 className="fw-bolder">
							{StringHelpers.title(item.name)}
						</h4>
						<p>{StringHelpers.title(item.course)}</p>
						{item.distributor && (
							<p>{StringHelpers.title(item.distributor)}</p>
						)}
						<div className="d-flex justify-content-between mt-auto">
							<span className="fw-bold fs-4">{price}</span>
							<div className="fw-light text-end">
								{item.joined ? (
									<Badge
										label={StringHelpers.upper(
											fields?.joined || 'JOINED'
										)}
										className="mb-1"
										variant={Variants.Success}
									/>
								) : (
									<div className="mb-1">
										{StringHelpers.title(
											fields?.availability ||
												'Availability'
										)}
										: {item.availability_percentage}%
									</div>
								)}
								<div>{formatted_date}</div>
							</div>
						</div>
					</CardBody>
				</Card>
			</div>
		);
	};

	return (
		<PageLayout title={crud?.models?.training_events || 'Training Events'}>
			<Grid<Item>
				endpoint={route(API_ENDPOINTS.TRAINING.EVENT.INDEX)}
				renderItem={renderItem}
				searchable
				pagination
			/>
		</PageLayout>
	);
}

export default TrainingEvents;
